import styled, { css } from 'styled-components';

import responsive from 'theme/responsive';

import Footer from './Footer';

const StyledFooter = styled(Footer)`
    font-size: 13px;
    width: 100%;
    background-color: ${(props) => props.theme.colours.primary};
    padding: 50px 30px 80px;
    color: #fff;

    ${responsive.sm(css`
        padding: 50px 0 80px;
    `)}

    @font-face {
        font-family: 'cards';
        src: url('/assets/fonts/cards.eot?9dp41r');
        src: url('/assets/fonts/cards.ttf?9dp41r') format('truetype'),
            url('/assets/fonts/cards.woff?9dp41r') format('woff'),
            url('/assets/fonts/cards.svg?9dp41r#cards') format('svg');
        font-weight: normal;
        font-style: normal;
    }

    .footer-card-logos {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 30px;

        ${responsive.sm(css`
            flex-wrap: no-wrap;
            justify-content: flex-end;
        `)}

        ${responsive.md(css`
            margin: 0;
        `)}

        & > * {
            margin-left: 20px;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .copyright {
        font-size: 16px;
        text-align: center;
        display: block;

        ${responsive.md(css`
            text-align: left;
            display: inline;
        `)}
    }

    .columns {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        ${responsive.sm(css`
            padding: 0 0 20px;
            flex-direction: column-reverse;
            align-items: center;
        `)}

        ${responsive.md(css`
            flex-direction: row;
            align-items: flex-start;
        `)}

        &.align-center {
            align-items: center;
        }
    }

    .address-columns {
        display: flex;
        flex-direction: row;
        padding: 0 20px 20px;
        align-items: flex-start;
        justify-content: center;

        ${responsive.sm(css`
            align-items: center;
            justify-content: space-between;
        `)}

        ${responsive.md(css`
            flex-direction: row;
        `)}
    }

    .logos {
        display: flex;
        align-items: center;
        justify-content: center;

        ${responsive.sm(css`
            justify-content: center;
        `)}

        & > * {
            margin-right: 20px;

            &:first-child {
                margin-right: 30px;
                flex-grow: 1;

                ${responsive.sm(css`
                    flex-grow: 0;
                `)}
            }
        }
    }

    .left-col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 30px;

        ${responsive.md(css`
            width: 510px;
            margin: 0;
        `)}
    }

    .footer-text {
        margin: 50px 0;
        text-align: center;

        p {
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 20px;
        }
    }

    .footer-address {
        flex-basis: 200px;
        text-align: center;
        display: none;

        ${responsive.sm(css`
            display: block;
        `)}

        ${responsive.md(css`
            text-align: left;
        `)}
    }

    .travel-aware {
        flex-basis: 250px;
        text-align: center;
    }

    .right-col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .menus {
        display: flex;
        margin-bottom: 20px;
        justify-content: space-evenly;

        ${responsive.md(css`
            margin-bottom: 0;
            justify-content: initial;
        `)}
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0 0 0 40px;

        ${responsive.sm(css`
            margin: 0 0 0 90px;
        `)}

        &:first-child {
            margin: 0;
        }

        li {
            list-style-image: none;
            list-style-type: none;
            margin-left: 0;
            white-space: nowrap;
            margin-bottom: 20px;
        }

        a {
            color: ${(props) => props.theme.colours.white};
            font-size: 16px;
            font-weight: 700;
            text-decoration: none;
            display: block;
            line-height: 16px;
            height: 16px;
            overflow: hidden;
        }
    }

    a {
        color: ${(props) => props.theme.colours.white};
    }
`;

export default StyledFooter;
