/* eslint-disable no-param-reassign */
import { breakpoints } from 'theme/breakpoints';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    size: null,
    width: null,
    height: null,
};

const slice = createSlice({
    name: 'browser',
    initialState,
    reducers: {
        updateBrowserSize(state, action) {
            state.width = action.payload.width;
            state.height = action.payload.height;

            const size = Object.entries(breakpoints)
                .reverse()
                .reduce(
                    // eslint-disable-next-line no-confusing-arrow
                    (s, t) =>
                        t[1] < action.payload.width && t[1] > s[1] ? t : s,
                    ['xs', 0],
                );

            [state.size] = size;
        },
    },
});

export default slice;

export const { updateBrowserSize } = slice.actions;

export const { reducer } = slice;

export const selectBrowser = (state) => state.browser;
