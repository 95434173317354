/* eslint-disable object-curly-newline */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { logout, refreshAsync } from 'store/slices/auth';

export const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const { accessToken } = getState().auth;

        if (accessToken) {
            headers.set('authorization', `Bearer ${accessToken}`);
        }

        return headers;
    },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        // try to get a new token
        const refreshResult = await baseQuery(
            '/user/refresh',
            api,
            extraOptions,
        );

        if (!refreshResult.error && refreshResult.data) {
            await api.dispatch(refreshAsync(refreshResult.data));

            // retry the initial query
            result = await baseQuery(args, api, extraOptions);
        } else {
            api.dispatch(logout());
        }
    }

    return result;
};

export const api = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: () => ({}),
    tagTypes: ['User', 'Results', 'Filters', 'Checkout'],
});
