import { css } from 'styled-components';

import responsive from './responsive';

export const heading = {
    default: {
        h1: css`
            font-family: 'Quicksand', sans-serif;
            font-size: 4.8rem;
            font-weight: 500;
            line-height: 5.2rem;
            margin-bottom: 2rem;

            ${responsive.sm(css`
                font-size: 9.6rem;
                line-height: 10.6rem;
            `)}
        `,
        h2: css`
            font-family: 'Quicksand', sans-serif;
            font-size: 4rem;
            font-weight: 500;
            line-height: 5.2rem;
            margin-bottom: 2rem;

            ${responsive.sm(css`
                font-size: 6rem;
                font-weight: 500;
                line-height: 6.8rem;
            `)}
        `,
        h3: css`
            font-family: 'Quicksand', sans-serif;
            font-size: 3.6rem;
            font-weight: 500;
            line-height: 3.4rem;
            margin-bottom: 2rem;

            ${responsive.sm(css`
                font-size: 4.8rem;
                line-height: 5.8rem;
            `)}
        `,
        h4: css`
            font-family: 'Quicksand', sans-serif;
            font-size: 2.3rem;
            font-weight: 500;
            line-height: 2.8rem;
            margin-bottom: 2rem;

            ${responsive.sm(css`
                font-size: 3.2rem;
                line-height: 3.8rem;
            `)}
        `,
        h5: css`
            font-family: 'Quicksand', sans-serif;
            font-size: 2rem;
            font-weight: 500;
            line-height: 2.8rem;
            margin-bottom: 2rem;

            ${responsive.sm(css`
                font-size: 2.4rem;
                line-height: 3.4rem;
            `)}
        `,
        h6: css`
            font-family: 'Quicksand', sans-serif;
            font-size: 1.6rem;
            font-weight: 500;
            letter-spacing: 0.2rem;
            line-height: 2rem;
            margin-bottom: 2rem;
            text-transform: uppercase;
        `,
        h7: css`
            font-family: 'Quicksand', sans-serif;
            font-size: 1.2rem;
            font-weight: 500;
            letter-spacing: 0.2rem;
            line-height: 2rem;
            margin-bottom: 2rem;
            text-transform: uppercase;
        `,
    },
};
