/* eslint-disable indent */
import { Home, NotFound } from 'pages';
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import accountRoutes from './account';
import checkoutRoutes from './checkout';
import hotelRoutes from './hotels';
import pagesRoutes from './pages';
import searchRoutes from './search';
import Status from 'pages/Status/Status';
import WithHeaderFooter from 'templates/WithHeaderFooter';

const routes = [
    {
        path: '/',
        key: 'HOME',
        component: Home,
        template: WithHeaderFooter,
        exact: true,
    },
    ...hotelRoutes,
    ...searchRoutes,
    ...checkoutRoutes,
    ...accountRoutes,
    ...pagesRoutes,
    {
        path: '/404',
        key: '404',
        component: NotFound,
        template: WithHeaderFooter,
        exact: true,
    },
    {
        path: '/status',
        key: 'STATUS',
        component: Status,
        exact: true,
    },
    {
        key: 'DEFAULT',
        component: NotFound,
        template: WithHeaderFooter,
        exact: true,
    },
];

export function Routes() {
    return (
        <Switch>
            {routes.map((route) => (
                <Route
                    key={route.key}
                    path={route.path}
                    exact={route.exact}
                    render={(props) => {
                        if (route.template) {
                            if (route.guard) {
                                return (
                                    <route.guard>
                                        <route.template>
                                            <route.component {...props} />
                                        </route.template>
                                    </route.guard>
                                );
                            }

                            return (
                                <route.template>
                                    <route.component {...props} />
                                </route.template>
                            );
                        }

                        return <route.component {...props} />;
                    }}
                />
            ))}
        </Switch>
    );
}
