import styled from 'styled-components';

import Button from './Button';

const StyledButton = styled(Button)`
    border-width: 0;
    border-radius: ${(props) => props.theme.corners.full}px;
    cursor: pointer;
    display: inline-flex;
    font-family: 'Quicksand';
    font-size: 1.8rem;
    font-weight: 500;
    text-decoration: none;
    outline: none;
    align-items: center;
    padding: 1rem 2rem;

    ${(props) => {
        const theme = props.btnStyle || 'default';
        const strength = props.disabled ? 50 : 100;

        return props.theme.buttons[theme][strength];
    }}

    ${(props) =>
        props.fullWidth &&
        `
        width: 100%;
        justify-content: center;
    `}

    ${(props) =>
        props.thick &&
        `
        padding: 1.5rem 2rem;
    `}

    ${(props) =>
        props.icon &&
        `
        justify-content: space-between;

        .button-icon {
            height: 20px;
            overflow: hidden;
        }
    `}
`;

export default StyledButton;
