export const colours = {
    default: {
        primary: '#33A7EC',
        secondary: '#FCB517',

        lightBlue: 'rgba(105,113,123,0.1)',
        black: '#151516',
        lightGrey: '#EEF0F7',
        grey: '#8997AA',
        darkBlue: '#14273C',
        white: '#fff',
        mediumGrey: '#E5E8EE',
        errorBackground: '#fce4e4',
        errorBorder: '#cc0033',
        errorColor: '#cc0033',
    },
    hoteles: { primary: '#107AF6', secondary: '#FF7715' },
};
