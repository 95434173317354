import { reducer as authReducer } from './slices/auth';
import { reducer as browserReducer } from './slices/browser';
import { reducer as modalReducer } from './slices/modal';
import { reducer as searchReducer } from './slices/search';
import { reducer as searchBoxReducer } from './slices/searchBox';
import { combineReducers } from '@reduxjs/toolkit';
import { api } from 'store/queries';

const rootReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    browser: browserReducer,
    modal: modalReducer,
    search: searchReducer,
    searchBox: searchBoxReducer,
});

export default rootReducer;
