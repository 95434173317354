import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'store';

import { closeModal } from 'store/slices/modal';

const NavigationItem = ({ className, label, url, inModal = false }) => {
    const dispatch = useDispatch();

    return (
        <Link
            to={`${url === '/' ? url : `/${url}`}`}
            className={className}
            onClick={inModal ? () => dispatch(closeModal()) : null}
            // eslint-disable-next-line indent
        >
            {label}
        </Link>
    );
};

export default NavigationItem;
