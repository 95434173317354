import React, { Fragment } from 'react';

import Footer from 'components/Footer';
import Header from 'components/Header';

const WithHeaderFooter = ({ children }) => (
    <>
        <Header />
        {children}
        <Footer />
    </>
);

export default WithHeaderFooter;
