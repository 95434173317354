import NavigationItem from './NavigationItem';
import styled from 'styled-components';

const StyledNavigationItem = styled(NavigationItem)`
    background: transparent;
    border: none;
    cursor: pointer;
    display: block;
    color: ${(props) => props.theme.colours.grey};
    font-size: 1.6rem;
    line-height: 5.2rem;
    padding: 0;
    text-decoration: none;
`;

export default StyledNavigationItem;
