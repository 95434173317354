import styled, { css } from 'styled-components';

import responsive from 'theme/responsive';

import NavigationList from './NavigationList';

const StyledNavigationList = styled(NavigationList)`
    ${responsive.sm(css`
        width: ${(props) => (props.width ? `${props.width}px` : '100%')};
    `)}

    ul {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        ${responsive.sm(css`
            flex-direction: row;
        `)}
    }

    li {
        margin: 0;

        ${responsive.sm(css`
            margin-left: 2rem;

            &:first-child {
                margin-left: 0;
            }
        `)}
    }
`;

export default StyledNavigationList;
