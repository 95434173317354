import React from 'react';

const Loading = ({ className }) => (
    <div className={className}>
        <div className="lds-ellipsis">
            <div />
            <div />
            <div />
            <div />
        </div>
    </div>
);

export default Loading;
