import { SearchResults } from 'pages';

import WithHeaderFooter from 'templates/WithHeaderFooter';

const searchRoutes = [
    {
        key: 'SEARCH_RESULTS',
        exact: true,
        path: '/search/results.html',
        component: SearchResults,
        template: WithHeaderFooter,
    },
];

export default searchRoutes;
