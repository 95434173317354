/* eslint-disable react/jsx-indent */

/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';

import { useGetAuthenticatedUserQuery } from 'store/queries/user';
import { redirectTo } from 'store/slices/auth';

const AuthGuard = ({ children }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState(false);
    const { isFetching, isSuccess, refetch } = useGetAuthenticatedUserQuery();
    const { isAuthenticated, authenticatedUntil } = useSelector(
        (state) => state.auth,
    );

    useEffect(() => {
        if (isAuthenticated && Date.now() > authenticatedUntil) {
            refetch();
            return null;
        }

        if (
            !isFetching &&
            (!isAuthenticated ||
                (isAuthenticated && Date.now() > authenticatedUntil))
        ) {
            dispatch(redirectTo(location.pathname));
            setRedirect(true);
        }

        return null;
    }, [
        isFetching,
        isAuthenticated,
        authenticatedUntil,
        location.pathname,
        dispatch,
        refetch,
    ]);

    if (redirect) {
        return (
            <Redirect
                to={{
                    pathname: '/account/user-login',
                }}
            />
        );
    }

    return isSuccess &&
        (isAuthenticated ||
            (isAuthenticated && Date.now() < authenticatedUntil)) ? (
        <>{children}</>
    ) : null;
};

export default AuthGuard;
