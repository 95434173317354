import React, { Fragment } from 'react';

const FooterText = () => (
    <>
        <div className="footer-text">
            <p>
                Hootels.co.uk is a holiday search website acting as agent only
                in providing access to the widest choice of accommodation around
                the world.
            </p>
            <p>
                Each product is booked by you with the travel provider and
                creates a contract directly between you and the provider of that
                product.
            </p>
            <p>
                Our team has close relations with many of the hotels and
                wholesalers providing us with the very best prices and allowing
                us to provide you with a quality service we believe is second to
                none. We do not sell, organise or arrange package holidays, nor
                do we sell flights
            </p>
        </div>
    </>
);

export default FooterText;
