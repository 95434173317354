/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
    isAuthenticated: false,
    authenticatedUntil: 0,
    accessToken: null,
    redirectTo: null,
    customer: null,
};

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authenticate(state, action) {
            state.accessToken = action.payload.access_token;
            state.isAuthenticated = true;
            state.authenticatedUntil =
                Date.now() + action.payload.expires_in * 60000;
        },
        redirectTo(state, action) {
            state.redirectTo = action.payload;
        },
        logout(state) {
            state.accessToken = null;
            state.isAuthenticated = false;
            state.authenticatedUntil = 0;
        },
        updateCustomer(state, action) {
            state.customer = { booking: { id: action.payload } };
        },
    },
});

export default slice;

export const authenticateAsync = createAsyncThunk(
    'auth/authenticateAsync',
    async (response, { dispatch }) => {
        try {
            await dispatch(slice.actions.authenticate(response));
        } catch (err) {
            await dispatch(slice.actions.logout());
        }

        return response;
    },
);

export const refreshAsync = createAsyncThunk(
    'auth/refreshAsync',
    async (data, { dispatch }) => {
        await dispatch(slice.actions.authenticate(data));

        return data;
    },
);

export const { redirectTo, logout, updateCustomer } = slice.actions;

export const { reducer } = slice;

export const selectCustomer = (state) => state.auth.customer;
