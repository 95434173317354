import styled, { css } from 'styled-components';

import responsive from 'theme/responsive';

import Container from './Container';

const StyledContainer = styled(Container)`
    margin: 0 auto;

    ${responsive.sm(css`
        width: 720px;
    `)}

    ${responsive.md(css`
        width: 940px;
    `)}

    ${responsive.lg(css`
        width: 1280px;
    `)}

    &:before {
        display: table;
        content: ' ';
    }

    &:after {
        display: table;
        content: ' ';
        clear: both;
    }
`;

export default StyledContainer;
