import React from 'react';
import ReactModal from 'react-modal';
import { useSelector, useDispatch } from 'store';

import Button from 'components/Button';

import ModalInsert from './ModalInsert';
import {
    closeModal,
    selectFullScreen,
    selectIsOpen,
    selectMeta,
    selectModalId,
} from 'store/slices/modal';

const Modal = ({ className }) => {
    const dispatch = useDispatch();
    const id = useSelector(selectModalId);
    const meta = useSelector(selectMeta);
    const fullScreen = useSelector(selectFullScreen);
    const isOpen = useSelector(selectIsOpen);

    const fullScreenContent = {
        top: '0',
        right: '0',
        left: '0',
        bottom: '0',
        borderRadius: '0',
        borderWidth: '0',
    };

    const notFullScreenContent = {
        left: '50%',
        right: 'auto',
        marginRight: '-50%',
        transform: 'translateX(-50%)',
        borderRadius: '50px',
        overflow: 'hidden',
    };

    const customStyles = {
        overlay: { zIndex: '9999' },
        content: fullScreen ? fullScreenContent : notFullScreenContent,
    };

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={() => dispatch(closeModal())}
            style={customStyles}
            appElement={document.getElementById('root') || undefined}
            // eslint-disable-next-line indent
        >
            <div className={className}>
                <ModalInsert
                    type={id}
                    meta={meta}
                    handleModalClose={() => dispatch(closeModal())}
                />
                <Button
                    className="modal__close"
                    type="default"
                    action={() => dispatch(closeModal())}
                />
            </div>
        </ReactModal>
    );
};

export default Modal;
