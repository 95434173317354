/* eslint-disable implicit-arrow-linebreak */
import { countAdults, countChildren } from './countOccupancy';
import pluralize from 'pluralize';

export const peopleString = (rooms) =>
    `${pluralize('Adult', countAdults(rooms), true)}, ${pluralize(
        'Children',
        countChildren(rooms),
        true,
    )}`;

export const occupancyString = (rooms) =>
    `${pluralize('Room', rooms.length, true)} (${peopleString(rooms)})`;
