/* eslint-disable indent */
import classnames from 'classnames';
import React from 'react';

const Image = ({
    className,
    src,
    alt,
    width,
    responsive,
    fullSize,
    onClick,
}) => (
    <div
        className={classnames(className, {
            responsive,
            'full-size': fullSize,
        })}
        style={fullSize && { backgroundImage: `url('${src}')` }}
        onClick={onClick}
    >
        {!fullSize && <img src={src} alt={alt} width={width} />}
    </div>
);

export default Image;
