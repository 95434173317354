import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'store';

import BurgerMenu from 'components/BurgerMenu';
import Button from 'components/Button';
import Container from 'components/Container';
import Logo from 'components/Logo';
import Navigation from 'components/Navigation';

import { logout } from 'store/slices/auth';
import { selectBrowser } from 'store/slices/browser';
import { openModal } from 'store/slices/modal';

const Header = ({ className, menu = true }) => {
    const auth = useSelector((state) => state.auth);
    const browser = useSelector(selectBrowser);
    const dispatch = useDispatch();

    return (
        <Container>
            <div className={className}>
                {menu ? (
                    <Link to="/" replace className="logo-link">
                        <Logo height={browser.size === 'xs' ? '50px' : null} />
                    </Link>
                ) : (
                    <Logo />
                )}
                {menu && (
                    <div className="body">
                        <div className="navigation">
                            <Navigation modal={false} />
                        </div>
                        <div className="cta">
                            <Button internal url="/account">
                                {auth.isAuthenticated
                                    ? 'My Account'
                                    : 'Sign In'}
                            </Button>
                            {auth.isAuthenticated ? (
                                <Button action={() => dispatch(logout())}>
                                    Sign Out
                                </Button>
                            ) : null}
                        </div>

                        <BurgerMenu
                            onClick={() =>
                                dispatch(
                                    openModal({
                                        id: 'nav',
                                        meta: {},
                                        fullScreen: true,
                                    }),
                                )
                            }
                        />
                    </div>
                )}
            </div>
        </Container>
    );
};

export default Header;
