import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import ErrorBoundary from 'components/ErrorBoundary';

import App from './App';
import { AuthProvider } from './AuthProvider';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { theme } from './theme';

ReactDOM.render(
    <ErrorBoundary>
        <ThemeProvider theme={theme.default}>
            <ThemeProvider
                theme={theme[process.env.REACT_APP_THEME ?? 'default']}
            >
                <Provider store={store}>
                    <AuthProvider>
                        <App />
                    </AuthProvider>
                </Provider>
            </ThemeProvider>
        </ThemeProvider>
    </ErrorBoundary>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
