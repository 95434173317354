/* eslint-disable indent */
import { debounce } from 'lodash';
import { NotFound } from 'pages';
import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from 'routes';
import { useDispatch } from 'store';

import ScrollToTop from 'services/ui/ScrollToTop';

import Base from 'components/Base';
import ErrorBoundary from 'components/ErrorBoundary';
import Modal from 'components/Modal';

import { updateBrowserSize } from 'store/slices/browser';
import WithHeaderFooter from 'templates/WithHeaderFooter';

const getWindowWidth = () => document.documentElement.clientWidth;
const getWindowHeight = () => document.documentElement.clientHeight;

function App() {
    const dispatch = useDispatch();

    const handleScreenResize = () => {
        window.addEventListener(
            'resize',
            debounce(() => {
                dispatch(
                    updateBrowserSize({
                        width: getWindowWidth(),
                        height: getWindowHeight(),
                    }),
                );
            }, 100),
        );

        dispatch(
            updateBrowserSize({
                width: getWindowWidth(),
                height: getWindowHeight(),
            }),
        );
    };

    const handleUnMount = () => {
        window.removeEventListener('resize', () => {
            dispatch(
                updateBrowserSize({
                    width: getWindowWidth(),
                    height: getWindowHeight(),
                }),
            );
        });
    };

    useEffect(() => {
        handleScreenResize();

        return handleUnMount;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Helmet>
                <title>
                    Cheap Hotels from {process.env.REACT_APP_SITE_NAME}
                </title>
            </Helmet>
            <Base />
            <BrowserRouter>
                <ErrorBoundary
                    FallbackComponent={() => (
                        <WithHeaderFooter component={NotFound} />
                    )}
                >
                    <ScrollToTop />
                    <Routes />
                    <Modal />
                </ErrorBoundary>
            </BrowserRouter>
        </>
    );
}

export default App;
