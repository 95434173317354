import {
    useDispatch as useReduxDispatch,
    useSelector as useReduxSelector,
} from 'react-redux';

import rootReducer from './rootReducer';
import { configureStore } from '@reduxjs/toolkit';
import { api } from 'store/queries';

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(api.middleware),
    devTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === 'true',
});

export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();

export default store;
