import React from 'react';

import NavigationItem from 'components/Navigation/child-components/NavigationItem';

const NavigationList = (props) => {
    const { className, items } = props;

    return (
        <nav className={className}>
            <ul>
                {items
                    .filter((item) =>
                        props.modalOnly === true ? true : !item.modalOnly,
                    )
                    .map((item) => (
                        <li key={item.id}>
                            <NavigationItem
                                {...item}
                                inModal={props.modalOnly}
                            />
                        </li>
                    ))}
            </ul>
        </nav>
    );
};

export default NavigationList;
