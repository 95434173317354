import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'store';

import Container from 'components/Container';
import Icon from 'components/Icon';
import Image from 'components/Image/Image';
import Logo from 'components/Logo';
import Text from 'components/Text';

import FooterText from './FooterText';
import { selectBrowser } from 'store/slices/browser';

const Footer = ({ className, menu = true }) => {
    const browser = useSelector(selectBrowser);

    return (
        <footer className={className}>
            <Container>
                <div className="logos">
                    {menu ? (
                        <Link to="/" replace>
                            <Logo fontColor="#fff" iconColor="#fff" />
                        </Link>
                    ) : (
                        <Logo fontColor="#fff" iconColor="#fff" />
                    )}
                    {menu && (
                        <>
                            <a
                                href="https://www.facebook.com/hootels.co.uk"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Icon
                                    type="facebook"
                                    width="30px"
                                    height="30px"
                                    fill="#fff"
                                />
                            </a>
                            <a
                                href="https://www.instagram.com/hootels.co.uk"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Icon
                                    type="instagram"
                                    width="30px"
                                    height="30px"
                                    fill="#fff"
                                />
                            </a>
                            <a
                                href="https://twitter.com/hootels_co_uk"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Icon
                                    type="twitter"
                                    width="30px"
                                    height="30px"
                                    fill="#fff"
                                />
                            </a>
                        </>
                    )}
                </div>
                {menu && browser.size !== 'xs' && <FooterText />}
                <div className="columns">
                    <section className="left-col">
                        <div className="address-columns">
                            <div className="footer-address">
                                <Text>
                                    Hootels
                                    <br />
                                    Oak Business Centre
                                    <br />
                                    79-93 Ratcliffe Road
                                    <br />
                                    Sileby
                                    <br />
                                    Loughborough
                                    <br />
                                    LE12 7PU
                                    <br />
                                    United Kingdom
                                </Text>
                            </div>
                            <div className="travel-aware">
                                <Image src="/assets/img/travel-aware.png" />
                                <Text>
                                    Check out the latest foreign advice{' '}
                                    <a href="https://www.gov.uk/foreign-travel-advice">
                                        https://www.gov.uk/foreign-travel-advice
                                    </a>
                                </Text>
                            </div>
                        </div>
                    </section>
                    <section className="right-col">
                        {menu && (
                            <div className="menus">
                                <ul>
                                    <li>
                                        <Link to="/about-us.html">
                                            About Us
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/hotels.html">Hotels</Link>
                                    </li>
                                    <li>
                                        <Link to="/checklist.html">
                                            Checklist
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/faq.html">FAQ</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact-us.html">
                                            Contact Us
                                        </Link>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <Link to="/brexit.html">
                                            Brexit Information
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/terms-and-conditions.html">
                                            Terms and Conditions
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/privacy-policy.html">
                                            Privacy Policy
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/cookies.html">Cookies</Link>
                                    </li>
                                    <li>
                                        <Link to="/price-match.html">
                                            Lowest Price Guarantee
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </section>
                </div>
                <div className="columns align-center">
                    <section className="left-col">
                        <Text>
                            <span className="copyright">
                                &copy; Hootels.co.uk. All rights reserved.
                            </span>
                        </Text>
                    </section>
                    <section className="right-col">
                        <div className="footer-card-logos">
                            <Icon type="white-visa" width={82} />
                            <Icon type="mastercard" width={69} />
                            <Icon type="maestro" width={69} />
                            <Icon type="jcb" height={50} />
                        </div>
                    </section>
                </div>
            </Container>
        </footer>
    );
};

export default Footer;
