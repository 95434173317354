import { useEffect, useRef, lazy } from 'react';

export const boardTypes = {
    1: 'All Inclusive',
    2: 'Full Board',
    3: 'Half Board',
    4: 'Bed & Breakfast',
    5: 'Room Only',
    6: 'Self Catering',
};

export const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }

        return undefined;
    }, [delay]);
};

export const lazyWithRetry = (componentImport) =>
    lazy(async () => {
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(
            window.localStorage.getItem('page-has-been-force-refreshed') ||
                'false',
        );

        try {
            const component = await componentImport();

            window.localStorage.setItem(
                'page-has-been-force-refreshed',
                'false',
            );

            return component;
        } catch (error) {
            if (!pageHasAlreadyBeenForceRefreshed) {
                // Assuming that the user is not on the latest version of the application.
                // Let's refresh the page immediately.
                window.localStorage.setItem(
                    'page-has-been-force-refreshed',
                    'true',
                );
                return window.location.reload();
            }

            // The page has already been reloaded
            // Assuming that user is already using the latest version of the application.
            // Let's let the application crash and raise the error.
            throw error;
        }
    });
