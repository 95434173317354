import {
    About,
    Brexit,
    Checklist,
    Contact,
    Cookies,
    Faq,
    PriceMatch,
    PrivacyPolicy,
    Terms,
} from 'pages';

import WithHeaderFooter from 'templates/WithHeaderFooter';

const pagesRoutes = [
    {
        key: 'CHECKLIST',
        exact: true,
        path: '/checklist.html',
        component: Checklist,
        template: WithHeaderFooter,
    },
    {
        key: 'CONTACT_US',
        exact: true,
        path: '/contact-us.html',
        component: Contact,
        template: WithHeaderFooter,
    },
    {
        key: 'COOKIES',
        exact: true,
        path: '/cookies.html',
        component: Cookies,
        template: WithHeaderFooter,
    },
    {
        key: 'ABOUT_US',
        exact: true,
        path: '/about-us.html',
        component: About,
        template: WithHeaderFooter,
    },
    {
        key: 'FAQS',
        exact: true,
        path: '/faq.html',
        component: Faq,
        template: WithHeaderFooter,
    },
    {
        key: 'BREXIT',
        exact: true,
        path: '/brexit.html',
        component: Brexit,
        template: WithHeaderFooter,
    },
    {
        key: 'PRICE_MATCH',
        exact: true,
        path: '/price-match.html',
        component: PriceMatch,
        template: WithHeaderFooter,
    },
    {
        key: 'PRIVACY_POLICY',
        exact: true,
        path: '/privacy-policy.html',
        component: PrivacyPolicy,
        template: WithHeaderFooter,
    },
    {
        key: 'TERMS',
        exact: true,
        path: '/terms-and-conditions.html',
        component: Terms,
        template: WithHeaderFooter,
    },
];

export default pagesRoutes;
