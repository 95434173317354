import styled, { css } from 'styled-components';

import responsive from 'theme/responsive';

import Logo from './Logo';

export const StyledLogo = styled(Logo)`
    height: 59px;
    display: inline-block;
    overflow: hidden;

    ${responsive.md(css`
        height: 59px;
    `)}
`;

export default StyledLogo;
