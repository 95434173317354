import { breakpoints } from './breakpoints';
import { css } from 'styled-components';

const responsive = {
    sm: (styles) => css`
        @media screen and (min-width: ${`${breakpoints.sm}px`}) {
            ${styles}
        }
    `,
    md: (styles) => css`
        @media screen and (min-width: ${`${breakpoints.md}px`}) {
            ${styles}
        }
    `,
    lg: (styles) => css`
        @media screen and (min-width: ${`${breakpoints.lg}px`}) {
            ${styles}
        }
    `,
};

export default responsive;
