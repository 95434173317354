import { buttons } from './buttons';
import { colours } from './colours';
import { corners } from './corners';
import { heading } from './heading';
import { sections } from './sections';

export const theme = {
    default: {
        name: 'default',
        colours: colours.default,
        sections: sections.default,
        buttons: buttons.default,
        heading: heading.default,
        corners: corners.default,
    },
    hootels: { name: 'hootels' },
    hoteles: {
        name: 'hoteles',
        colours: { ...colours.default, ...colours.hoteles },
        corners: corners.hoteles,
    },
};
