import styled, { css } from 'styled-components';

import responsive from 'theme/responsive';

import Header from './Header';

const StyledHeader = styled(Header)`
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    ${responsive.sm(css`
        padding: 0;
        height: 80px;
    `)}

    .logo-link {
        height: 50px;

        ${responsive.sm(css`
            padding: 0;
            height: 59px;
        `)}
    }

    .body {
        display: flex;
    }

    .navigation {
        display: none;
        margin-right: 20px;

        ${responsive.sm(css`
            display: block;
        `)}
    }

    .cta {
        display: none;

        ${responsive.sm(css`
            display: block;
        `)}
    }
`;

export default StyledHeader;
