import React from 'react';

import { useGetAuthenticatedUserQuery } from 'store/queries/user';

export const AuthProvider = ({ children }) => {
    useGetAuthenticatedUserQuery();

    return <>{children}</>;
};

export default AuthProvider;
