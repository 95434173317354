import { Checkout } from 'pages';

import NoHeaderFooter from 'templates/NoHeaderFooter';

const checkoutRoutes = [
    {
        key: 'BOOKING_LOGIN',
        exact: true,
        path: '/checkout/:step.html',
        component: Checkout,
        template: NoHeaderFooter,
    },
];

export default checkoutRoutes;
