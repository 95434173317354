/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
    id: null,
    pollingCount: 0,
    completed: false,
    perPage: 9,
    firstItem: 0,
    lastItem: 0,
    sort: 'rating_desc',
    page: 1,
    filters: {
        price: {
            min: 0,
            max: 9999999,
        },
        name: '',
        resorts: [],
        landmarks: [],
        types: [],
        boards: [],
        ratings: [],
    },
    filtersOpen: false,
    mapOpen: false,
};

const slice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        resetSearch() {
            return initialState;
        },
        updateSearchId(state, action) {
            state.id = action.payload;
        },
        incrementPollingCount(state) {
            state.pollingCount += 1;
        },
        updateCompleted(state, action) {
            state.completed = action.payload;
        },
        updateFirstItem(state, action) {
            state.firstItem = action.payload;
        },
        updateLastItem(state, action) {
            state.lastItem = action.payload;
        },
        toggleMap(state) {
            state.mapOpen = !state.mapOpen;
        },
        closeMap(state) {
            state.mapOpen = false;
        },
        updateFilters(state, action) {
            const { category, values } = action.payload;
            state.filters[category] = values;
            state.page = 1;
        },
        toggleFilters(state) {
            state.filtersOpen = !state.filtersOpen;
        },
        updateSort(state, action) {
            state.sort = action.payload;
        },
        setPage(state, action) {
            state.page = action.payload;
        },
    },
});

export default slice;

export const {
    incrementPollingCount,
    toggleMap,
    closeMap,
    toggleFilters,
    updateFirstItem,
    updateLastItem,
    updateCompleted,
    updateSearchId,
    updateSort,
    updateFilters,
    setPage,
    resetSearch,
} = slice.actions;

export const { reducer } = slice;

export const selectSearchId = (state) => state.search.id;
export const selectPollingCount = (state) => state.search.pollingCount;
export const selectCompleted = (state) => state.search.completed;
export const selectPage = (state) => state.search.page;
export const selectSort = (state) => state.search.sort;
export const selectFilters = (state) => state.search.filters;
export const selectFiltersOpen = (state) => state.search.filtersOpen;
export const selectMapOpen = (state) => state.search.mapOpen;
export const selectFirstItem = (state) => state.search.firstItem;
export const selectLastItem = (state) => state.search.lastItem;
export const selectPerPage = (state) => state.search.perPage;

export const resetSearchAsync = createAsyncThunk(
    'search/resetSearchAsync',
    async (data, { dispatch }) => {
        await dispatch(slice.actions.resetSearch());

        return data;
    },
);

export const pollingAsync = createAsyncThunk(
    'search/pollingAsync',
    async (data, { dispatch }) => {
        await dispatch(slice.actions.incrementPollingCount());
        await dispatch(slice.actions.updateCompleted(data));

        return data;
    },
);

export const searchIdAsync = createAsyncThunk(
    'search/searchIdAsync',
    async (data, { dispatch }) => {
        await dispatch(slice.actions.updateSearchId(data));

        return data;
    },
);

export const setupPagination = createAsyncThunk(
    'search/setupPagination',
    async ({ firstItem, lastItem }, { dispatch }) => {
        await dispatch(slice.actions.updateFirstItem(firstItem));
        await dispatch(slice.actions.updateLastItem(lastItem));

        return { firstItem, lastItem };
    },
);
