import { Glyphs } from './glyphs';

/**
 * Utility to help below function
 */
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Icons are usually passed as hyphens
 * SVG factories are camelCased
 */
function hyphensToCamelCase(str) {
    const arr = str.split(/[_-]/);
    let newStr = '';
    for (let i = 1; i < arr.length; i++) {
        newStr += arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    return capitalizeFirstLetter(arr[0]) + newStr;
}

const Icon = (props) => {
    const { type } = props;
    const GlyphFactory = hyphensToCamelCase(type);
    const IconComponent = Glyphs[GlyphFactory];

    if (IconComponent) {
        return IconComponent({ ...props });
    }

    return null;
};

export default Icon;
