import React, { Fragment } from 'react';

import Footer from 'components/Footer';
import Header from 'components/Header';

const NoHeaderFooter = ({ children }) => (
    <>
        <Header menu={false} />
        {children}
        <Footer menu={false} />
    </>
);

export default NoHeaderFooter;
