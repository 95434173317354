/* eslint-disable indent */
import styled, { css } from 'styled-components';

import responsive from 'theme/responsive';

import BurgerMenu from './BurgerMenu';

const StyledBurgerMenu = styled(BurgerMenu)`
    align-items: center;
    display: flex;

    ${responsive.sm(css`
        display: none;
    `)}

    button {
        background: none;
        border: 0;
        border-radius: 2px;
        cursor: pointer;
        display: block;
        height: 5rem;
        margin: 0;
        padding: 0;
        transition: background-color linear 0.2s;
        width: 3rem;
        background-color: ${(props) => props.theme.colours.white};

        span {
            background-color: ${(props) => props.theme.colours.grey};
            height: 0.3rem;
            display: block;
            left: 50%;
            position: relative;
            transform: translateX(-50%);
            transition: all linear 0.2s;
            width: 3rem;

            &:nth-child(1) {
                transform: translateX(-50%) translateY(-0.7rem);

                ${(props) =>
                    props.open &&
                    css`
                        background-color: ${props.theme.colours.grey};
                        transform: translateX(-50%) translateY(0.3rem)
                            rotate(45deg) scale(0.65, 1.1);
                    `}
            }

            &:nth-child(2) {
                opacity: ${(props) => props.open && 0};
            }

            &:nth-child(3) {
                transform: translateX(-50%) translateY(0.7rem);

                ${(props) =>
                    props.open &&
                    css`
                        background-color: ${props.theme.colours.grey};
                        transform: translateY(-0.3rem) translateX(-50%)
                            rotate(-45deg) scale(0.65, 1.1);
                    `}
            }
        }
    }
`;

export default StyledBurgerMenu;
