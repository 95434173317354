import React from 'react';
import styled, { css } from 'styled-components';

import responsive from 'theme/responsive';

import Container from 'components/Container';

import { useGetAuthenticatedUserQuery } from 'store/queries/user';

const AccountWelcomePage = ({ className }) => {
    const { data: user, isSuccess } = useGetAuthenticatedUserQuery();

    return isSuccess ? (
        <div className={className}>
            <Container>
                <div className="inner">Welcome {user.name}</div>
            </Container>
        </div>
    ) : null;
};

const StyledAccountWelcomePage = styled(AccountWelcomePage)`
    border-top: 1px solid ${(props) => props.theme.colours.lightGrey};

    .inner {
        margin: 20px;

        ${responsive.sm(css`
            width: 650px;
            margin: 80px auto;
        `)}
    }

    h1 {
        text-align: center;
    }

    .fieldset {
        margin-bottom: 40px;
    }

    .error {
        color: red;
    }

    .text-input {
        margin-bottom: 20px;
    }

    .submit-button {
        text-align: center;
    }
`;

export default StyledAccountWelcomePage;
