import { css } from 'styled-components';

export const buttons = {
    default: {
        default: {
            100: css`
                background-color: ${(props) => props.theme.colours.primary};
                color: ${(props) => props.theme.colours.white};
            `,
            50: css`
                background-color: #bbdefb;
                color: ${(props) => props.theme.colours.white};
            `,
        },
        primary: {
            100: css`
                background-color: ${(props) => props.theme.colours.secondary};
                color: ${(props) => props.theme.colours.white};
            `,
        },
        grey: {
            100: css`
                background-color: ${(props) => props.theme.colours.lightGrey};
                color: ${(props) => props.theme.colours.primary};
            `,
        },
        bordered: {
            100: css`
                border: 1px solid ${(props) => props.theme.colours.primary};
                background-color: ${(props) => props.theme.colours.white};
                color: ${(props) => props.theme.colours.primary};
            `,
        },
        input: {
            100: css`
                background-color: ${(props) => props.theme.colours.white};
                color: ${(props) => props.theme.colours.black};
                border: 0.1rem solid ${(props) => props.theme.colours.grey};
                border-radius: ${(props) => props.theme.corners.full}px;
                box-sizing: border-box;
                font-family: 'Quicksand', sans-serif;
                font-size: 1.8rem;
                padding: 0 1.6rem;
                width: 100%;
                height: 57px;
                justify-content: space-between !important;
            `,
        },
    },
};
