const sample = {
    open: false,
    width: 310,
    items: [
        {
            id: 1,
            label: 'Home',
            url: '/',
            target: '_self',
            modalOnly: false,
        },
        {
            id: 2,
            label: 'Hotels',
            url: 'hotels.html',
            target: '_self',
            modalOnly: false,
        },
        {
            id: 3,
            label: 'FAQ',
            url: 'faq.html',
            target: '_self',
            modalOnly: false,
        },
        {
            id: 4,
            label: 'Contact Us',
            url: 'contact-us.html',
            target: '_self',
            modalOnly: false,
        },
        {
            id: 5,
            label: 'Manage My Booking',
            url: 'account/login',
            target: '_self',
            modalOnly: true,
        },
    ],
};

export default sample;
