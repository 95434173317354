import React from 'react';

import { ErrorBoundary } from 'services/logging';

const OurErrorBoundary = ({ children, FallbackComponent }) => (
    <ErrorBoundary FallbackComponent={FallbackComponent}>
        {children}
    </ErrorBoundary>
);

export default OurErrorBoundary;
