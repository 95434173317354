/* eslint-disable no-param-reassign */
import moment from 'moment';

import { occupancyString } from 'services/search/occupancyToStr';

import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';

const initialState = {
    errors: [],
    term: null,
    property: { id: null },
    resort: { id: null },
    destination: {
        id: null,
        name: null,
        slug: null,
    },
    country: {
        id: null,
        name: null,
        slug: null,
    },
    calendar: {
        open: false,
        pickingEnd: false,
        current: moment().add(2, 'day').toISOString(),
        arrival: moment().add(2, 'day').toISOString(),
        departure: moment().add(4, 'days').toISOString(),
    },
    occupancy: {
        rooms: [
            {
                id: null,
                adults: [{}, {}],
                children: [],
            },
        ],
    },
    occupancyPopup: { open: false },
};

const slice = createSlice({
    name: 'searchBox',
    initialState,
    reducers: {
        openCalendar(state) {
            state.calendar.open = true;
        },
        closeCalendar(state) {
            state.calendar.open = false;
        },
        updatePickingEnd(state, action) {
            state.calendar.pickingEnd = action.payload;
        },
        togglePickingEnd(state) {
            state.calendar.pickingEnd = !state.calendar.pickingEnd;
        },
        updateCurrent(state, action) {
            state.calendar.current = action.payload;
        },
        nextMonth(state) {
            state.calendar.current = moment(state.calendar.current)
                .add(1, 'month')
                .toISOString();
        },
        prevMonth(state) {
            state.calendar.current = moment(state.calendar.current)
                .subtract(1, 'month')
                .toISOString();
        },
        setArrival(state, action) {
            state.calendar.arrival = action.payload;
        },
        setDeparture(state, action) {
            state.calendar.departure = action.payload;
        },
        updateTerm(state, action) {
            state.term = action.payload;
        },
        updateDestination(state, action) {
            state.term = action.payload.term;
            state.property = action.payload.property;
            state.resort = action.payload.resort;
            state.destination = action.payload.destination;
            state.country = action.payload.country;
        },
        openOccupancyPopup(state) {
            state.occupancyPopup.open = true;
        },
        closeOccupancyPopup(state) {
            state.occupancyPopup.open = false;
        },
        changeRoomCount(state, action) {
            if (action.payload < state.occupancy.rooms.length) {
                // user wants to remove a room(s)
                const deleteTotal =
                    state.occupancy.rooms.length - action.payload;

                for (let i = 1; i <= deleteTotal; i++) {
                    state.occupancy.rooms.pop();
                }
            } else if (action.payload > state.occupancy.rooms.length) {
                // user wants to add a room(s)
                const addTotal = action.payload - state.occupancy.rooms.length;

                for (let i = 1; i <= addTotal; i++) {
                    state.occupancy.rooms = state.occupancy.rooms.concat([
                        {
                            adults: [{}, {}],
                            children: [],
                        },
                    ]);
                }
            }
        },
        changeAdultsCount(state, action) {
            const { key, value } = action.payload;

            if (typeof key !== 'undefined') {
                const room = state.occupancy.rooms[key];
                const currentAdults = room.adults.length;
                const newAdults = value;

                if (newAdults < currentAdults) {
                    // user wants to remove an adult(s)
                    const deleteTotal = currentAdults - newAdults;
                    for (let i = 1; i <= deleteTotal; i++) {
                        state.occupancy.rooms[key].adults.pop();
                    }
                } else if (newAdults > currentAdults) {
                    // user wants to add an adult(s)
                    const addTotal = newAdults - currentAdults;

                    for (let i = 1; i <= addTotal; i++) {
                        state.occupancy.rooms[
                            key
                        ].adults = state.occupancy.rooms[key].adults.concat([
                            {},
                        ]);
                    }
                }
            }
        },
        changeChildrenCount(state, action) {
            const { key, value } = action.payload;

            if (typeof key !== 'undefined') {
                const room = state.occupancy.rooms[key];
                const currentChildren = room.children.length;
                const newChildren = value;

                if (newChildren < currentChildren) {
                    // user wants to remove a child(s)
                    const deleteTotal = currentChildren - newChildren;

                    for (let i = 1; i <= deleteTotal; i++) {
                        state.occupancy.rooms[key].children.pop();
                    }
                } else if (newChildren > currentChildren) {
                    // user wants to add a child(s)
                    const addTotal = newChildren - currentChildren;

                    for (let i = 1; i <= addTotal; i++) {
                        state.occupancy.rooms[
                            key
                        ].children = state.occupancy.rooms[
                            key
                        ].children.concat([{ age: null }]);
                    }
                }
            }
        },
        changeChildAge(state, action) {
            const { key, childkey, value } = action.payload;

            if (typeof key !== 'undefined') {
                state.occupancy.rooms[key].children[childkey].age = value;
            }
        },
        setOccupancy(state, action) {
            state.occupancy = action.payload;
        },
        updateSearchBox(state, action) {
            state.property.id = action.payload.propertyId ?? state.property.id;
            state.resort.id = action.payload.resortId ?? state.resort.id;
            state.destination.id = action.payload.destinationId;
            state.country.id = action.payload.countryId;
            state.calendar.arrival =
                action.payload.arrival ?? state.calendar.arrival;
            state.calendar.departure =
                action.payload.departure ?? state.calendar.departure;
            state.term = action.payload.term ?? state.term;
        },
    },
});

export default slice;

export const {
    openCalendar,
    closeCalendar,
    updatePickingEnd,
    togglePickingEnd,
    updateCurrent,
    nextMonth,
    prevMonth,
    updateDestination,
    setArrival,
    setDeparture,
    openOccupancyPopup,
    closeOccupancyPopup,
    changeRoomCount,
    changeAdultsCount,
    changeChildrenCount,
    changeChildAge,
    setOccupancy,
    updateSearchBox,
} = slice.actions;

export const { reducer } = slice;

export const isCalendarOpen = (state) => state.searchBox.calendar.open;
export const selectEnd = (state) => state.searchBox.calendar.pickingEnd;
export const selectTerm = (state) => state.searchBox.term;
export const selectProperty = (state) => state.searchBox.property;
export const selectResort = (state) => state.searchBox.resort;
export const selectDestination = (state) => state.searchBox.destination;
export const selectCountry = (state) => state.searchBox.country;
export const isOccupancyPopupOpen = (state) =>
    state.searchBox.occupancyPopup.open;
export const selectRooms = (state) => state.searchBox.occupancy.rooms;

export const selectCurrent = createSelector(
    (state) => state.searchBox.calendar.current,
    (current) => moment(current),
);

export const selectArrival = createSelector(
    (state) => state.searchBox.calendar.arrival,
    (arrival) => moment(arrival),
);

export const selectDeparture = createSelector(
    (state) => state.searchBox.calendar.departure,
    (current) => moment(current),
);

export const selectOccupancyString = createSelector(
    (state) => state.searchBox.occupancy.rooms,
    (rooms) => occupancyString(rooms),
);

export const searchBoxAsync = createAsyncThunk(
    'search/searchBoxAsync',
    async (data, { dispatch }) => {
        await dispatch(slice.actions.updateDestination(data));
        await dispatch(slice.actions.setArrival(data.arrival));
        await dispatch(slice.actions.setDeparture(data.departure));
        await dispatch(slice.actions.setOccupancy(data.occupancy));

        return data;
    },
);
