// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.REACT_APP_BUGSNAG_ENV,
    enabledReleaseStages: ['production', 'staging'],
});

export const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(
    React,
);

export const Logger = Bugsnag;
