/* eslint-disable indent */
import Modal from './Modal';
import styled, { css } from 'styled-components';

const StyledModal = styled(Modal)`
    ${(props) =>
        props.bgColor &&
        css`
            background-color: ${props.bgColor};
        `}
    overflow-y: auto;
    max-height: 100%;

    .modal__video-player {
        height: ${(props) => (props.fullScreen ? '100vh' : '70vh')};
        width: ${(props) => (props.fullScreen ? '100vw' : '90vw')};
    }

    .modal__content {
        height: 100%;
        width: 100%;
        position: relative;
        border-radius: ${(props) => !props.fullScreen && '0.5rem'};
        ${(props) =>
            props.contentOverflow &&
            css`
                overflow: ${props.contentOverflow};
            `}
    }

    .modal__content__insert {
        height: 100%;
        position: relative;
        width: ${(props) => props.modalWidth};
    }

    .modal__close-text {
        line-height: 2rem;
        position: absolute;
        right: 9rem;
        top: 6rem;
        height: 4rem;
        z-index: 1;
    }

    .modal__close {
        position: relative;
        font-size: 2rem;
        line-height: 2rem;
        position: absolute;
        left: 2rem;
        top: 2rem;
        height: 5rem;
        padding: 1rem 1.9rem;
        width: 5rem;
        z-index: 1;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 2.3ßrem;
            display: block;
            width: 2rem;
            height: 0.28rem;
            background-color: ${(props) => props.theme.colours.white};
            transition: background-color linear 0.2s;
        }

        &:before {
            left: 1.5rem;
            transform: rotate(45deg);
        }

        &:after {
            right: 1.5rem;
            transform: rotate(135deg);
        }

        &::-moz-focus-inner {
            border: 0;
        }
    }
`;

export default StyledModal;
