import React from 'react';

const BurgerMenu = ({ className, onClick }) => (
    <div className={className}>
        <button onClick={onClick} type="button">
            <span />
            <span />
            <span />
        </button>
    </div>
);

export default BurgerMenu;
