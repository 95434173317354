/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isOpen: false,
    id: null,
    meta: null,
    fullScreen: false,
};

const slice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openModal(state, action) {
            state.id = action.payload.id;
            state.meta = action.payload.meta;
            state.isOpen = true;
            state.fullScreen = action.payload.fullScreen;
        },
        closeModal(state) {
            state.id = null;
            state.meta = null;
            state.isOpen = false;
        },
    },
});

export default slice;

export const { openModal, closeModal } = slice.actions;

export const { reducer } = slice;

export const selectIsOpen = (state) => state.modal.isOpen;
export const selectModalId = (state) => state.modal.id;
export const selectMeta = (state) => state.modal.meta;
export const selectFullScreen = (state) => state.modal.fullScreen;
