import styled from 'styled-components';

import Text from './Text';

const StyledText = styled(Text)`
    font-weight: ${(props) => props.weight && props.weight};
    margin-bottom: ${(props) => (props.margin ? props.margin : '2rem')};

    &:last-child {
        margin-bottom: 0;
    }

    b {
        font-weight: 700;
    }
`;

export default StyledText;
