import { api } from '.';

export const userApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAuthenticatedUser: build.query({
            query: () => ({ url: '/user/me', method: 'get' }),
            providesTags: ['User'],
            overrideExisting: true,
        }),
    }),
});

export const { useGetAuthenticatedUserQuery } = userApi;
