import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const Button = ({
    className,
    children,
    action,
    url,
    fake,
    type,
    internal,
    submit,
    icon,
    disabled,
}) => {
    let buttonContent;

    switch (type) {
        default:
            buttonContent = (
                <>
                    <span>{children}</span>
                </>
            );
    }

    if (fake) {
        return (
            <span className={className}>
                {buttonContent}
                {icon && <span className="button-icon">{icon}</span>}
            </span>
        );
    }

    if (action) {
        return (
            <button
                className={className}
                onClick={action}
                disabled={disabled}
                type="button"
                // eslint-disable-next-line indent
            >
                {buttonContent}
                {icon && <span className="button-icon">{icon}</span>}
            </button>
        );
    }

    if (submit) {
        return (
            <button className={className} type="submit" disabled={disabled}>
                {buttonContent}
                {icon && <span className="button-icon">{icon}</span>}
            </button>
        );
    }

    if (internal && url) {
        return (
            <Link replace to={url} className={className}>
                {buttonContent}
                {icon && <span className="button-icon">{icon}</span>}
            </Link>
        );
    }

    return (
        <a className={className} href={url} target="_blank" rel="noreferrer">
            {buttonContent}
            {icon && <span className="button-icon">{icon}</span>}
        </a>
    );
};

export default Button;
