import React, { Suspense } from 'react';

import { lazyWithRetry } from 'services/global';

// Loaders are used for code splitting
const LazyHome = lazyWithRetry(() => import('./Home'));
const LazyCountryList = lazyWithRetry(() => import('./CountryList'));
const LazyDestinationList = lazyWithRetry(() => import('./DestinationList'));
const LazyResortList = lazyWithRetry(() => import('./ResortList'));
const LazyPropertyList = lazyWithRetry(() => import('./PropertyList'));
const LazySearchResults = lazyWithRetry(() => import('./SearchResults'));
const LazyPropertyPage = lazyWithRetry(() => import('./PropertyPage'));
const LazyCheckout = lazyWithRetry(() => import('./Checkout'));
const LazyChecklist = lazyWithRetry(() => import('./Checklist'));
const LazyFaq = lazyWithRetry(() => import('./Faq'));
const LazyAbout = lazyWithRetry(() => import('./About'));
const LazyTerms = lazyWithRetry(() => import('./Terms'));
const LazyPrivacyPolicy = lazyWithRetry(() => import('./PrivacyPolicy'));
const LazyCookies = lazyWithRetry(() => import('./Cookies'));
const LazyPriceMatch = lazyWithRetry(() => import('./PriceMatch'));
const LazyContact = lazyWithRetry(() => import('./Contact'));
const LazyBrexit = lazyWithRetry(() => import('./Brexit'));
const LazyAccountLogin = lazyWithRetry(() => import('./Account/Login'));
const LazyNotFound = lazyWithRetry(() => import('./NotFound'));

const Home = (props) => (
    <Suspense fallback={<div />}>
        <LazyHome {...props} />
    </Suspense>
);

const CountryList = (props) => (
    <Suspense fallback={<div />}>
        <LazyCountryList {...props} />
    </Suspense>
);

const DestinationList = (props) => (
    <Suspense fallback={<div />}>
        <LazyDestinationList {...props} />
    </Suspense>
);

const ResortList = (props) => (
    <Suspense fallback={<div />}>
        <LazyResortList {...props} />
    </Suspense>
);

const PropertyList = (props) => (
    <Suspense fallback={<div />}>
        <LazyPropertyList {...props} />
    </Suspense>
);

const PropertyPage = (props) => (
    <Suspense fallback={<div />}>
        <LazyPropertyPage {...props} />
    </Suspense>
);

const SearchResults = (props) => (
    <Suspense fallback={<div />}>
        <LazySearchResults {...props} />
    </Suspense>
);

const Checkout = (props) => (
    <Suspense fallback={<div />}>
        <LazyCheckout {...props} />
    </Suspense>
);

const Checklist = (props) => (
    <Suspense fallback={<div />}>
        <LazyChecklist {...props} />
    </Suspense>
);

const Faq = (props) => (
    <Suspense fallback={<div />}>
        <LazyFaq {...props} />
    </Suspense>
);

const About = (props) => (
    <Suspense fallback={<div />}>
        <LazyAbout {...props} />
    </Suspense>
);

const Terms = (props) => (
    <Suspense fallback={<div />}>
        <LazyTerms {...props} />
    </Suspense>
);

const PrivacyPolicy = (props) => (
    <Suspense fallback={<div />}>
        <LazyPrivacyPolicy {...props} />
    </Suspense>
);

const Cookies = (props) => (
    <Suspense fallback={<div />}>
        <LazyCookies {...props} />
    </Suspense>
);

const PriceMatch = (props) => (
    <Suspense fallback={<div />}>
        <LazyPriceMatch {...props} />
    </Suspense>
);

const Contact = (props) => (
    <Suspense fallback={<div />}>
        <LazyContact {...props} />
    </Suspense>
);

const Brexit = (props) => (
    <Suspense fallback={<div />}>
        <LazyBrexit {...props} />
    </Suspense>
);
const AccountLogin = (props) => (
    <Suspense fallback={<div />}>
        <LazyAccountLogin {...props} />
    </Suspense>
);

const NotFound = (props) => (
    <Suspense fallback={<div />}>
        <LazyNotFound {...props} />
    </Suspense>
);

export {
    Home,
    CountryList,
    DestinationList,
    ResortList,
    PropertyList,
    PropertyPage,
    SearchResults,
    Checkout,
    Checklist,
    Faq,
    About,
    Terms,
    PrivacyPolicy,
    Cookies,
    PriceMatch,
    AccountLogin,
    Contact,
    Brexit,
    NotFound,
};
