import { css } from 'styled-components';

import { colours } from './colours';

export const sections = {
    default: {
        padding: {
            none: '0',
            xs: '5rem',
            sm: '10rem',
            md: '12rem',
            lg: '15rem',
        },
        types: {
            default: css`
                padding: 3rem 0;
            `,
            lightBlue: css`
                background-color: ${colours.default.lightBlue};
            `,
            secondary: css`
                background-color: ${colours.default.secondary};
            `,
            blue: css`
                background-color: ${colours.default.primary};
            `,
        },
    },
};
