import React from 'react';

import Navigation from 'components/Navigation';

import PageInsert from './child-components/PageInsert';

const ModalInsert = ({ type, meta }) => {
    let Child = null;
    const m = { ...meta };

    switch (type) {
        case 'nav':
            m.modalOnly = true;
            Child = Navigation;
            break;
        case 'page':
            m.contentOnly = true;
            Child = PageInsert;
            break;
        default:
            Child = null;
    }

    return Child ? React.createElement(Child, { type, ...m }) : null;
};

export default ModalInsert;
