import {
    CountryList,
    PropertyPage,
    PropertyList,
    ResortList,
    DestinationList,
} from 'pages';

import WithHeaderFooter from 'templates/WithHeaderFooter';

const hotelRoutes = [
    {
        key: 'COUNTRY_LIST',
        exact: true,
        path: '/hotels.html',
        component: CountryList,
        template: WithHeaderFooter,
    },
    {
        key: 'DESTINATION_LIST',
        exact: true,
        path: '/hotels/:countrySlug.html',
        component: DestinationList,
        template: WithHeaderFooter,
    },
    {
        key: 'RESORT_LIST',
        exact: true,
        path: '/hotels/:countrySlug/:destinationSlug.html',
        component: ResortList,
        template: WithHeaderFooter,
    },
    {
        key: 'PROPERTY_LIST',
        exact: true,
        path:
            '/hotels/:countrySlug/:destinationSlug/hotels-in-:resortSlug.html',
        component: PropertyList,
        template: WithHeaderFooter,
    },
    {
        key: 'PROPERTY_PAGE',
        exact: true,
        path: '/hotels/:countrySlug/:destinationSlug/:propertySlug.html',
        component: PropertyPage,
        template: WithHeaderFooter,
    },
];

export default hotelRoutes;
