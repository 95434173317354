import AuthGuard from 'AuthGuard';
import { AccountLogin } from 'pages';

import WelcomePage from 'pages/Account/Welcome';
import WithHeaderFooter from 'templates/WithHeaderFooter';

const accountRoutes = [
    /* {
        key: 'BOOKING_LOGIN',
        exact: true,
        path: '/account/login',
        component: BookingLogin,
        template: WithHeaderFooter,
    },
    {
        key: 'BOOKING PAGE',
        exact: true,
        path: '/account/booking/:bookingId',
        component: AccountBookingDashboard,
        template: WithHeaderFooter,
    }, */
    {
        key: 'ACCOUNT_LOGIN',
        exact: true,
        path: '/account/user-login',
        component: AccountLogin,
        template: WithHeaderFooter,
    },
    {
        key: 'ACCOUNT_DASHBOARD',
        exact: true,
        path: '/account',
        component: WelcomePage,
        guard: AuthGuard,
        template: WithHeaderFooter,
    },
];

export default accountRoutes;
