import { createGlobalStyle } from 'styled-components';

import Reset from 'components/Reset';

import '@fontsource/quicksand/400.css';
import '@fontsource/quicksand/500.css';
import '@fontsource/quicksand/700.css';
import '@fontsource/quicksand/latin-400.css';
import '@fontsource/quicksand/latin-500.css';
import '@fontsource/quicksand/latin-700.css';

const Base = createGlobalStyle`
    ${Reset}

    html {
        font-size: 10px;
    }

    body {
        font-family: 'Quicksand', sans-serif;
        font-weight: 400;
        color: ${(props) => props.theme.colours.grey};
        font-size: 1.6rem;
        line-height: 2.6rem;

        &.ReactModal__Body--open {
            overflow: hidden !important;
        }
    }

    #root {
        overflow-x: hidden;
        position: relative;
    } 
`;

export default Base;
