import React, { useEffect, useState } from 'react';

import { api } from 'store/queries';

const Status = () => {
    const [status, setStatus] = useState({});

    useEffect(() => {
        api.get(`/status`, {
            params: {
                feEnv: process.env.REACT_APP_ENV,
                screenWidth:
                    window.innerWidth ||
                    document.documentElement.clientWidth ||
                    document.body.clientWidth,
                screenHeight:
                    window.innerHeight ||
                    document.documentElement.clientHeight ||
                    document.body.clientHeight,
                timezoneOffset: new Date().getTimezoneOffset(),
                // eslint-disable-next-line no-restricted-globals
                colourDepth: screen.colorDepth,
            },
        })
            .then((response) => {
                setStatus(response.data);
            })
            .catch((error) => {
                setStatus(error);
            });
    }, []);

    return (
        <div>
            <pre>{JSON.stringify(status, undefined, 4)}</pre>
        </div>
    );
};

export default Status;
